// apiClient.ts
import { ChatRequest, ChatResponse } from 'app/api/types/types'

const API_URL = '/api/prompt'

export async function fetchChatResponse(
  request: ChatRequest,
): Promise<ChatResponse> {
  const response = await fetch(API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(request),
  })

  if (!response.ok) {
    throw new Error('Failed to fetch chat response')
  }

  return await response.json()
}
