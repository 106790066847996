'use client'
import React, { useEffect, useState } from 'react'
import { Box, Button } from '@mantine/core'
import { FaPlay, FaRegClipboard } from 'react-icons/fa'
import {
  dataValuesObj,
  scriptResults,
  activeBottomView,
  scriptResultsInitiated,
} from '../../store/stakzStore'
import scriptToFunction, {
  StakzExecution,
  StakzParseError,
} from '../../service/scriptEval'
import { useIsClient } from '../../service/hooks/customHooks'
import { useSetAtom, useAtomValue, useAtom } from 'jotai'
import AceEditor from 'components/ace-editor'

export default function ScriptWidget(props: {
  script: string
  onUpdate?: (newValue: string) => void
}) {
  const data = useAtomValue(dataValuesObj)
  const [autoReloadScript, setAutoReloadScript] = useState(false)
  const setActiveTab = useSetAtom(activeBottomView)

  function computeScript(
    script: string,
  ): StakzExecution | StakzParseError | undefined {
    if (!autoReloadScript) {
      return undefined
    }
    return scriptToFunction(script, data)
  }

  const client = useIsClient()
  const [script, setScript] = useState(props.script)
  const [result, setResult] = useAtom(scriptResults)
  const setScriptResultsInitiated = useSetAtom(scriptResultsInitiated)

  useEffect(() => {
    const result = computeScript(script)
    setResult(result)
    if (result?.type === 'success') {
      navigator.clipboard.writeText(result.result)
    }
  }, [autoReloadScript, data])

  useEffect(() => {
    setScript(props.script)
  }, [props.script])

  function focusTerminal() {
    setAutoReloadScript(true)
    setActiveTab('terminal')
    setScriptResultsInitiated()
    const terminal = document.getElementById('terminal')
    if (terminal) {
      terminal.focus()
    }
  }

  return (
    <Box>
      {client && (
        <div className='relative flex'>
          <div className='flex flex-column'>
            <Button
              className='m-2'
              onClick={focusTerminal}
              variant='subtle'
              color='green'
              radius='md'
              size='lg' // Increase the size of the button
              style={{ padding: '0.75em', position: 'relative' }} // Adjust padding to ensure the icon is not cut off
            >
              <FaPlay />
              <FaRegClipboard
                style={{
                  position: 'absolute',
                  top: '30px',
                  left: '26px',
                  fontSize: '0.5em',
                }}
              />
            </Button>
          </div>
          <AceEditor
            value={script}
            highlightActiveLine={false}
            className='bg-neutral-800 border-none flex-1'
            mode='javascript'
            wrapEnabled={true}
            minLines={10}
            setOptions={{ enableLiveAutocompletion: true }}
            theme='nord_dark'
            maxLines={60}
            onChange={newScript => {
              setScript(newScript)
              setResult(computeScript(newScript))
              props.onUpdate?.(newScript)
            }}
            name='ScriptWidget'
            editorProps={{
              $blockScrolling: true,
            }}
          />
        </div>
      )}
    </Box>
  )
}
