import { useState } from 'react'
import { HiClipboardCopy } from 'react-icons/hi'
import { Check } from 'tabler-icons-react'

export default function ServerUnhealthy({ style }: { style?: any }) {
  const [copied, setIsCopied] = useState(false)
  return (
    <div className='bg-[rgb(43,43,43)] z-50 absolute p-2 rounded' style={style}>
      <pre className='text-white text-sm mb-2'>
        The server is not responding. To run the Stakz-server, please run
      </pre>

      <div
        className={`cursor-pointer bg-neutral-700 p-1 rounded flex ${copied && 'ring ring-green-700'}`}
        onMouseDown={() => {
          setIsCopied(true)
          navigator.clipboard.writeText(
            'mkdir -p stakz \\\n && docker run -p 3001:3001 \\\n--mount type=bind,source=./stakz,target=/app/stakz-example \\\n--name stakz-server curtismj1/stakz',
          )
        }}
      >
        <pre className='text-white text-sm m-2'>
          mkdir -p stakz && \<br />
          docker run -p 3001:3001 \<br />
          &emsp;--mount type=bind,source=./stakz,target=/app/stakz-example \
          <br />
          &emsp;--name stakz-server curtismj1/stakz
        </pre>
        {copied ? (
          <Check className='m-2 text-green-700' />
        ) : (
          <HiClipboardCopy className='m-2' />
        )}
      </div>
    </div>
  )
}
