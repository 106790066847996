import {
  File,
  GithubFile,
  githubFileService,
  LocalFile,
  localFileService,
} from 'app/api/fileService'

import { atom } from 'jotai'
import { equal } from '../app/api/fileService'
import { stakzFileContext } from './stakzStore'

const _activeViewType = atom<'browse' | 'editor'>('browse')
const _openFiles = atom<File[]>([])

const defaultGithubFile: GithubFile = {
  type: 'github',
  org: 'curtismj',
  repo: 'stakz-example',
  path: 'index.md',
}
const defaultLocalFile: LocalFile = { type: 'local', path: '' }

// Define a side effect atom to handle the async function
const setFilesEffect = atom(
  null,
  async (_, set, viewType: 'browse' | 'editor') => {
    const fs = viewType === 'browse' ? githubFileService : localFileService
    const newFiles = await fs.getFiles()
    set(currentFiles, newFiles)
    if (!_activeFileForViewType.get(viewType) && newFiles.length > 0) {
      set(currentFile, newFiles.find(f => f.path === 'index.md') || newFiles[0])
    }
  },
)

const setFileContentEffect = atom(null, async (_, set, file: File) => {
  if (file.content) {
    set(stakzFileContext, file.content)
  } else {
    const content =
      file.type === 'github'
        ? await githubFileService.getFileContent(file)
        : await localFileService.getFileContent(file)
    file.content = content
    set(stakzFileContext, content)
  }
})

export const activeViewType = atom(
  get => get(_activeViewType),
  (_, set, newViewType: 'browse' | 'editor') => {
    set(_activeViewType, newViewType)
    set(setFilesEffect, newViewType) // Trigger the side effect atom
    const newFileForViewType = _activeFileForViewType.get(newViewType)
    newFileForViewType && set(currentFile, newFileForViewType)
  },
)

const _activeFileForViewType: Map<'browse' | 'editor', File | undefined> =
  new Map()
_activeFileForViewType.set('browse', defaultGithubFile)
_activeFileForViewType.set('editor', defaultLocalFile)

const _currentFile = atom<File>(defaultGithubFile)

export const currentFile = atom(
  get => get(_currentFile),
  (get, set, newFile: File) => {
    set(_activeViewType, newFile.type == 'local' ? 'editor' : 'browse')
    set(_currentFile, newFile)
    _activeFileForViewType.set(
      newFile.type == 'local' ? 'editor' : 'browse',
      newFile,
    )
    const newViewType = newFile.type == 'local' ? 'editor' : 'browse'
    set(_activeViewType, newViewType)
    set(setFilesEffect, newViewType) // Trigger the side effect atom
    set(setFileContentEffect, newFile)
    set(addOpenPath, newFile)
    if (newFile.type == 'local') {
      _activeFileForViewType.set('editor', newFile)
      window.history.pushState({}, '', `/editor/${newFile.path}`)
    } else {
      _activeFileForViewType.set('browse', newFile)
      window.history.pushState(
        {},
        '',
        `/browse/${newFile.org}/${newFile.repo}/${newFile.path}`,
      )
    }
  },
)

export const currentPath = atom(get => get(currentFile)?.path)
export const currentFiles = atom<File[]>([])
export const openFiles = atom(get => get(_openFiles))

export const addOpenPath = atom(null, (get, set, f: File) => {
  if (get(_openFiles).find(p => equal(p, f))) {
    return
  }
  set(_openFiles, [...get(_openFiles), f])
})

export const addOpenPaths = atom(null, (get, set, f: File[]) => {
  const openFiles = get(_openFiles)
  const newFiles = f.filter(p => !openFiles.find(p2 => equal(p2, p)))
  openFiles.push(...newFiles)
  set(_openFiles, openFiles)
})

export const closeFile = atom(null, (get, set, f: File) => {
  if (get(_openFiles).length === 1) {
    return
  }
  const newOpenFiles = get(_openFiles).filter(p => !equal(p, f))
  set(_openFiles, newOpenFiles)
  set(currentFile, newOpenFiles[0])
})

export const isLoadingPrompt = atom(false)
