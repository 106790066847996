import { Octokit } from '@octokit/rest'
import axios from 'axios'

import { getFileContent } from 'service/s3Service'

const octokit = new Octokit()

type BaseFile = {
  type: 'local' | 'github'
  path: string
  content?: string
}

export type LocalFile = BaseFile & {
  type: 'local'
}

export type GithubFile = BaseFile & {
  type: 'github'
  org: string
  repo: string
}

const apiClient = axios.create()

// Add a request interceptor
apiClient.interceptors.request.use(
  function (config) {
    // Do something before the request is sent
    config.headers['Authorization'] = localStorage
      .getItem('token')
      ?.replaceAll('"', '')
    return config
  },
  function (error) {
    // Do something with the request error
    return Promise.reject(error)
  },
)

export function equal(f: File, s: File) {
  if (f.type !== s.type) {
    return false
  }
  if (f.type === 'local') {
    return f.path === s.path
  } else if (s.type === 'github') {
    // This check is unnecessary based on code above, but
    // typescript can't figure out both types are guarenteed to be github on this branch
    return f.path === s.path && f.org === s.org && f.repo === s.repo
  }
}

export type File = LocalFile | GithubFile

export const ObjectUtils = {
  groupingBy<T>(
    path: string[],
    terminalFunc: () => T,
    currentObject: { [key: string]: any } = {},
  ) {
    if (path.length == 1) {
      currentObject[path[0]] = terminalFunc()
    } else {
      currentObject[path[0]] = {
        ...currentObject[path[0]],
        ...this.groupingBy(
          path.slice(1, path.length),
          terminalFunc,
          currentObject[path[0]] ?? {},
        ),
      }
    }
    return currentObject
  },

  filesToObject(files: File[]) {
    return files.reduce(
      (partial, nextFile) =>
        this.groupingBy(nextFile.path.split('/'), () => nextFile, partial),
      {},
    )
  },
}

export interface FileService<T extends File> {
  getFiles(): Promise<File[]>
  getFileContent(fileMetadata: T): Promise<string>
}

export function getGithubContent(owner: string, repo: string, path: string) {
  return octokit.rest.repos.getContent({
    owner,
    repo,
    path,
  })
}

export class GithubFileService implements FileService<GithubFile> {
  owner: string
  repo: string

  constructor(owner: string = 'curtismj1', repo = 'stakz-example') {
    this.owner = owner
    this.repo = repo
  }
  async getFiles(): Promise<File[]> {
    const response = await octokit.rest.git.getTree({
      owner: this.owner,
      repo: this.repo,
      tree_sha: 'main',
      recursive: 'true',
    })
    return response.data.tree
      .filter(d => d.type == 'blob')
      .map(resp => ({
        path: resp.path ?? '',
        type: 'github',
        org: this.owner,
        repo: this.repo,
      }))
  }

  async getFileContent(fileMetadata: GithubFile): Promise<string> {
    const content = await octokit.rest.repos.getContent({
      owner: fileMetadata.org,
      repo: fileMetadata.repo,
      path: fileMetadata.path,
    })
    // Get the file content if it is a file
    if (Array.isArray(content.data)) {
      return ''
    } else if (content.data.type === 'file') {
      return Buffer.from(content.data.content, 'base64').toString()
    } else {
      return ''
    }
  }
}

export class CachedFileService<T extends File> implements FileService<T> {
  fileService: FileService<T>
  private files?: File[]

  constructor(fileService: FileService<T>) {
    this.fileService = fileService
  }

  getFileContent(fileMetadata: T): Promise<string> {
    return this.fileService.getFileContent(fileMetadata)
  }

  async getFiles(): Promise<File[]> {
    if (this.files) {
      return Promise.resolve(this.files)
    }
    this.files = await this.fileService.getFiles()
    return this.files
  }
}

export class LocalFileService implements FileService<LocalFile> {
  url: string
  localFiles: LocalFile[] = []

  constructor(url: string = 'http://localhost:3001') {
    this.url = url
  }

  async getFileContent(fileMetadata: LocalFile): Promise<string> {
    return apiClient
      .get(this.url + '/content', { params: { file: fileMetadata.path } })
      .then(res => res.data)
  }

  async getFiles(): Promise<LocalFile[]> {
    try {
      const resp = await apiClient.get<{ files: string[] }>(this.url)
      this.localFiles = resp.data.files.map(fileUrl => ({
        path: fileUrl,
        type: 'local',
      }))
    } catch (e) {
      return []
    }
    return this.localFiles
  }
}

const uuidRegex = /.*[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/

export class LocalS3FileService implements FileService<LocalFile> {
  localFileService: LocalFileService
  constructor(localFileService: LocalFileService) {
    this.localFileService = localFileService
  }
  async getFileContent(fileMetadata: LocalFile): Promise<string> {
    // If the file ends with a UUID, it is an s3 file
    if (uuidRegex.test(fileMetadata.path)) {
      const content = await getFileContent(fileMetadata.path)
      fileMetadata.content = content
      return content
    }
    return this.localFileService.getFileContent(fileMetadata)
  }

  async getFiles(): Promise<LocalFile[]> {
    return this.localFileService.getFiles()
  }
}

export const githubFileService = new CachedFileService(new GithubFileService())
export const localFileService = new LocalS3FileService(new LocalFileService())

export { apiClient }
