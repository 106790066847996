'use client'

import { useCallback, useContext } from 'react'
import React from 'react'
import {
  FilePersistService,
  FilePersistServiceContext,
} from 'app/api/filePersistService'
import { debounce } from 'lodash'
import dynamic from 'next/dynamic'
import { stakzFileContext, viewType } from '../../store/stakzStore'
import { useAtom, useAtomValue } from 'jotai'
import StakzView from '../stakzView/layout'
import AceEditor from 'components/ace-editor'

type RowColumn = { row: number; column: number }
type AceEditEvent = {
  action: 'remove' | 'insert'
  start: RowColumn
  end: RowColumn
  lines: string[]
}

export default function MainContent(props: { filePath: string }) {
  const [content, setContent] = useAtom(stakzFileContext)
  const filePersistService = useContext(FilePersistServiceContext)
  const _viewType = useAtomValue(viewType)

  const onUpdate = useCallback(
    debounce((newVal: string) => {
      filePersistService.saveFile(props.filePath, newVal)
    }, 2500),
    [props.filePath],
  )

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
      }}
    >
      {(_viewType == 'editor' || _viewType == 'split') && (
        <AceEditor
          keyboardHandler='vim'
          value={content}
          className={'bg-neutral-800 border-none'}
          style={{
            flex: 1,
            padding: '4em',
            height: '100%',
            minWidth: '0',
            top: 0,
            left: 0,
            right: 0,
          }}
          height='100%'
          wrapEnabled={true}
          mode='markdown'
          theme='nord_dark'
          setOptions={{
            enableBasicAutocompletion: false,
          }}
          enableLiveAutocompletion={true}
          onChange={(content, event: AceEditEvent) => {
            setContent(content)
            onUpdate(content)
          }}
          name='UNIQUE_ID_OF_DIV'
          editorProps={{
            $blockScrolling: true,
          }}
        />
      )}
      {(_viewType == 'preview' || _viewType == 'split') && <StakzView />}
    </div>
  )
}
