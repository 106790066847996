'use client'
import React from 'react'
import { Autocomplete, Tabs } from '@mantine/core'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import {
  currentFile as currentFileAtom,
  openFiles as openFilesAtom,
  activeViewType as activeViewTypeAtom,
  closeFile as closeFileAtom,
} from '../../store/fileStore'
import { viewType } from '../../store/stakzStore'
import { File } from '../api/fileService'
import { AiOutlineClose } from 'react-icons/ai'

export default function HeaderSearch() {
  const [_viewType, setViewType] = useAtom(viewType)
  const [currentFile, setCurrentFile] = useAtom(currentFileAtom)
  const openFiles = useAtomValue(openFilesAtom)
  const closeFile = useSetAtom(closeFileAtom)

  const tabValue = (f: File | undefined) => {
    if (!f) {
      return ''
    }
    if (f.type == 'github') {
      return `${f.org}/${f.repo}/${f.path}`
    } else {
      return `${f.type}/${f.path}`
    }
  }

  const valueToFiles = (value: string) => {
    return openFiles.find(f => tabValue(f) === value)
  }

  return (
    <div className='flex justify-between flex-shrink-0 flex-grow-0'>
      <Tabs
        value={tabValue(currentFile)}
        onChange={value => {
          const file = valueToFiles(value ?? '')
          if (file) {
            setCurrentFile(file)
          }
        }}
      >
        <Tabs.List>
          {openFiles.map(f => (
            <Tabs.Tab key={tabValue(f)} value={tabValue(f)} className='m-2'>
              <div className='flex items-center'>
                <div className='flex-col items-center'>
                  <div className='text-xs text-gray-400'>
                    {f.type == 'local' ? f.type : `${f.org}/${f.repo}`}
                  </div>
                  <div>{f.path}</div>
                </div>
                <AiOutlineClose
                  size={20}
                  className='ml-2 cursor-pointer outline-2 hover:bg-neutral-800 rounded-full p-1'
                  onClick={e => {
                    e.stopPropagation()
                    closeFile(f)
                  }}
                />
              </div>
            </Tabs.Tab>
          ))}
        </Tabs.List>
      </Tabs>
    </div>
  )
}
