import { Textarea, Button } from '@mantine/core'
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { fetchChatResponse } from 'service/promptService'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import {
  addOpenPaths as addOpenPathsAtom,
  currentFile as currentFileAtom,
  isLoadingPrompt,
} from 'store/fileStore'
import {
  selectedModels as selectedModelsAtom,
  currentPrompt as currentPromptAtom,
} from 'store/promptStore'
import { FaPlay } from 'react-icons/fa'
import { LocalFile } from 'app/api/fileService'

export default function PromptEngineeringView() {
  const [currentPrompt, setCurrentPrompt] = useAtom(currentPromptAtom)
  const [currentFile, setCurrentFile] = useAtom(currentFileAtom)
  const [loading, setLoading] = useAtom(isLoadingPrompt)
  const selectedModels = useAtomValue(selectedModelsAtom)
  const selectedModelsRef = useRef(selectedModels)
  const addOpenPaths = useSetAtom(addOpenPathsAtom)

  useEffect(() => {
    selectedModelsRef.current = selectedModels
  }, [selectedModels])

  const runPrompt = useCallback(async () => {
    setLoading(true)
    const oldCurrentFile = currentFile
    try {
      const chatResponse = await fetchChatResponse({
        prompt: currentPrompt,
        selectedModels: selectedModelsRef.current,
      })
      if (chatResponse && chatResponse.responses.length > 0) {
        const files: LocalFile[] = chatResponse.responses.map(response => ({
          type: 'local',
          path: response.fileName,
          content: response.response,
        }))
        setCurrentFile(files[0])
        addOpenPaths(files)
      }
    } catch (error) {
      console.error('Error fetching chat response:', error)
    } finally {
      setLoading(false)
    }
  }, [currentPrompt, currentFile, setCurrentFile])

  useEffect(() => {
    const handleKeyDown = async (e: KeyboardEvent) => {
      if (e.ctrlKey && e.key === 'g') {
        e.preventDefault()
        runPrompt()
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [currentPrompt, runPrompt])

  return (
    <div className='size-full flex flex-col bg-neutral-800 text-white p-2'>
      <div className='relative'>
        <Textarea
          className='flex-1 mb-2 size-full'
          styles={{
            input: {
              height: '100%',
              resize: 'none',
              width: '100%',
            },
            wrapper: { height: '100%', width: '100%' },
          }}
          onChange={e => setCurrentPrompt(e.target.value)}
          value={currentPrompt}
          defaultValue={currentPrompt}
          disabled={loading}
        />
        <Button
          className='top-2 right-2 ml-2.5'
          onClick={runPrompt}
          disabled={loading}
        >
          <FaPlay size={16} />
        </Button>
      </div>
    </div>
  )
}
