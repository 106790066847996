import { atom } from 'jotai'

// define the array first, note the const
export const ChatModelValues = ['gpt-4o-mini', 'gemini'] as const
export type ChatModels = (typeof ChatModelValues)[number]
export const selectedModels = atom<ChatModels[]>(['gpt-4o-mini', 'gemini'])

export const currentPrompt = atom(
  'Generate a tutorial on how to provision a static html site using AWS s3 and route 53.',
)
