import React, { useState, useRef, useEffect } from 'react'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { MultiSelect, TextInput } from '@mantine/core'
import { FaTerminal, FaBrain } from 'react-icons/fa' // Import icons from react-icons
import { CiCircleAlert } from 'react-icons/ci'
import {
  executionStatus,
  executionOutput,
  scriptResults as scriptResultsAtom,
  activeBottomView,
  stakzServerHealthy as stakzServerHealthyAtom,
  executeScript as executeScriptAtom,
  token as tokenAtom,
  newScriptHash,
} from 'store/stakzStore'

import {
  selectedModels as selectedModelsAtom,
  ChatModelValues,
} from 'store/promptStore'

import SplitViewSwitch from 'components/splitviewSwitch'
import PromptEngineeringView from '../../components/terminal/promptEngineering'
import ServerUnhealthy from 'components/serverUnhealthy'

type TerminalViewProps = {
  execStatus: 'success' | 'failure'
  execOutput: string
  scriptResults: any
}

function TerminalView({
  execStatus,
  execOutput,
  scriptResults,
}: TerminalViewProps) {
  const executeScript = useSetAtom(executeScriptAtom)
  const execScriptRef = useRef<HTMLPreElement>(null)

  useEffect(() => {
    execScriptRef.current?.focus()
  }, [newScriptHash])

  return (
    <div className='flex-1 m-2 size-full flex flex-col relative overflow-auto'>
      <pre className='whitespace-pre-wrap flex-1' id='terminal'>
        <div className='flex ml-2'>
          <span style={{ color: execStatus == 'success' ? 'green' : 'red' }}>
            →{' '}
          </span>
          <pre
            contentEditable='true'
            ref={execScriptRef}
            className='w-full'
            onKeyDown={key => {
              if (
                key.key === 'Enter' &&
                !key.shiftKey &&
                execScriptRef.current
              ) {
                key.preventDefault()
                executeScript(execScriptRef.current?.innerText)
              }
            }}
          >
            {scriptResults.resText}
          </pre>
        </div>
        {execOutput && (
          <pre className='p-2 hover:bg-neutral rounded whitespace-pre-wrap bg-neutral-800 cursor-pointer'>
            {execOutput}
          </pre>
        )}
      </pre>
    </div>
  )
}

export default function Terminal() {
  const execStatus = useAtomValue(executionStatus)
  const execOutput = useAtomValue(executionOutput)
  const scriptResults = useAtomValue(scriptResultsAtom)
  const stakzServerHealthy = useAtomValue(stakzServerHealthyAtom)

  const [input, setInput] = useState('')
  const [token, setToken] = useAtom(tokenAtom)
  const textareaRef = useRef<HTMLElement>(null)
  const executeScript = useSetAtom(executeScriptAtom)
  const [isToolTipVisible, setIsTooltipVisible] = useState(false)
  const [copied, setIsCopied] = useState(false)
  const textRef = useRef<HTMLPreElement>(null)
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 })

  const handleInputChange = (e: any) => {
    setInput(e.target.value)
  }

  const handleTokenChange = (e: any) => {
    setToken(e.target.value)
  }

  const handleContainerClick = () => {
    if (textareaRef.current) {
      textareaRef.current.focus()
    }
  }

  const [panelHeight, setPanelHeight] = useState(300) // Initial height of the panel
  const panelRef = useRef<HTMLDivElement>(null)

  const handleMouseDown = (e: any) => {
    const startY = e.clientY
    const startHeight = panelRef.current?.offsetHeight ?? 0

    const onMouseMove = (e: any) => {
      const newHeight = startHeight + (e.clientY - startY)
      setPanelHeight(newHeight)
    }

    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove)
      document.removeEventListener('mouseup', onMouseUp)
    }

    document.addEventListener('mousemove', onMouseMove)
    document.addEventListener('mouseup', onMouseUp)
  }

  const [activeTab, setActiveTab] = useAtom(activeBottomView)
  const [selectedModels, setSelectedModels] = useAtom(selectedModelsAtom) // Default selected models

  useEffect(() => {
    if (isToolTipVisible && textRef.current) {
      const rect = textRef.current.getBoundingClientRect()
      setTooltipPosition({
        top: rect.bottom - rect.top, // Position just below the text
        left: rect.left - rect.left, // Align with the left of the text
      })
    }
  }, [isToolTipVisible])

  return (
    <div
      className='size-full flex-1 pl-4 pr-4 flex flex-col z-10 overflow-hidden'
      ref={panelRef}
    >
      <div className='z-10 sticky flex-0'>
        <div className='flex items-center justify-between p-2'>
          <div className='flex space-x-8'>
            <button
              className={`p-2 mr-4 w-32 ${activeTab === 'prompt-engineering' ? 'text-blue-500' : 'text-gray-500'}`}
              onClick={() => setActiveTab('prompt-engineering')}
              style={{
                borderBottom:
                  activeTab === 'prompt-engineering'
                    ? '2px solid #3b82f6'
                    : '2px solid transparent',
                marginRight: '0.5em',
              }}
            >
              <FaBrain size={20} />
            </button>
            <button
              className={`p-2 w-32 ${activeTab === 'terminal' ? 'text-blue-500' : 'text-gray-500'}`}
              onClick={() => setActiveTab('terminal')}
              style={{
                borderBottom:
                  activeTab === 'terminal'
                    ? '2px solid #3b82f6'
                    : '2px solid transparent',
              }}
            >
              <FaTerminal size={20} />
            </button>
          </div>
          {activeTab === 'prompt-engineering' && (
            <div className='flex-grow flex-shrink w-48'>
              <MultiSelect
                data={ChatModelValues}
                value={selectedModels}
                // @ts-ignore
                onChange={setSelectedModels}
                placeholder='Select chat models'
                styles={{
                  wrapper: {
                    flexGrow: 1,
                    flexShrink: 1,
                    width: '30%',
                  },
                  input: {
                    flexGrow: 1,
                    flexShrink: 1,
                  },
                }}
              />
            </div>
          )}
          {activeTab === 'terminal' && (
            <div
              id='serverStatus'
              className='flex-grow flex-shrink w-48 m-2 flex'
            >
              <TextInput
                className='w-1/3'
                value={token}
                onChange={handleTokenChange}
                placeholder='Enter token'
              />

              {!stakzServerHealthy && (
                <div
                  className='flex items-center ml-1 relative flex-0 '
                  style={{ zIndex: 10 }}
                  onMouseLeave={() => {
                    setIsTooltipVisible(false)
                    setIsCopied(false)
                  }}
                >
                  <CiCircleAlert
                    className='p-1 w-8 h-8 fill-red-600'
                    onMouseEnter={() => setIsTooltipVisible(true)}
                  />
                  {isToolTipVisible && (
                    <ServerUnhealthy
                      style={{
                        top: tooltipPosition.top,
                        left: tooltipPosition.left,
                      }}
                    />
                  )}
                </div>
              )}
            </div>
          )}
          <SplitViewSwitch />
        </div>

        <div
          className='w-full h-2 cursor-row-resize bg-neutral-700'
          onMouseDown={handleMouseDown}
        ></div>
      </div>
      <div className='flex-grow-1 flex overflow-scroll'>
        {activeTab === 'terminal' && (
          <TerminalView
            execStatus={execStatus}
            execOutput={execOutput}
            scriptResults={scriptResults}
          />
        )}
        {activeTab === 'prompt-engineering' && <PromptEngineeringView />}
      </div>
    </div>
  )
}
